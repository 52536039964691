import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import "./TwitterInvite.scss";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Twitter from "@mui/icons-material/Twitter";
import { CircularProgress } from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import logo from "../../images/logo.jpeg";
import CloseIcon from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";

const App = () => {
  const [twitterId, setTwitterId] = useState("");
  const [link, setLink] = useState("");
  const [userId, setUserId] = useState("");
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);

  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setUserId(params.id);
    }
  }, []);

  const generateLink = () => {
    setIsLoading(true);
    axios
      .post(`https://nfdna-5a8df.hq.spicaengine.com/api/fn-execute/generateLink`, {
        twitterId: twitterId,
        userId: userId,
      })
      .then((res: any) => {
        setLink(`http://nfdna.club/participate/${res.data?.userId}`);
      })
      .finally(() => {
        setTwitterId("");
        setIsLoading(false);
        setAccepted(true);
        toast.success("Completed successfully");
      });
  };

  return (
    <div className="TwitterInvite">
      <Toaster />
      <img src={logo} className="Logo" onClick={() => window.location.replace('http://nfdna.club/')}/>
      <h1>To participate in the giveaway</h1>

      {userId ? (
        <ul>
          <li>Follow Us</li>
          <li>Entry your Twitter ID</li>
          <li>Accept the invitation</li>
        </ul>
      ) : (
        <ul>
          <li>Follow Us</li>
          <li>Entry your Twitter ID</li>
          <li>Share your reference link</li>
          <li>Retweet our pinned post</li>
        </ul>
      )}
      <div className="FollowUs">
        <Twitter />
        <a href="https://twitter.com/nfdnaclub" target="_blank">
          Follow Us
        </a>
      </div>

      <input
        placeholder="Twitter Id"
        onChange={(event) => setTwitterId(event.target.value)}
      ></input>
      <button onClick={() => generateLink()} disabled={twitterId.length < 1 || isLoading}>
        {!isLoading ? (
          userId ? (
            <span>Accept invitation</span>
          ) : (
            <span>Generate Link</span>
          )
        ) : (
          <CircularProgress size={25} className="Spinner" />
        )}
      </button>

      <div className={`LinkContainer ${link ? "ShowLink" : ""}`}>
        <CloseIcon className="CloseBtn" onClick={() => setLink("")} />

        {link && (
          <div className="LinkContent">
            <span>Link : </span>
            <p>{link}</p>
            <CopyToClipboard
              text={link}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 1000);
              }}
            >
              <ContentCopyIcon className="Clipboard" />
            </CopyToClipboard>
            {copied && <span className="Copied">Copied</span>}
            {userId && accepted && (
              <p className="RefferalLinkText">
                Share your referral link to increase your chance to win the giveaway
              </p>
            )}
          </div>
        )}
      </div>
      <div className="light x4"></div>
      <div className="light x5"></div>
      <div className="light x6"></div>
      <div className="light x7"></div>
      <div className="light x8"></div>
      <div className="light x9"></div>
    </div>
  );
};

export default App;
