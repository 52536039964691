import { Icon } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";

import "./Countdown.scss";

export const Countdown = ({ date, isCounting = true, showType }: any) => {
  const [counter, setCounter] = useState<any>();
  useEffect(() => {
    var countDownDate = new Date(date).getTime();

    const interval = setInterval(function () {
      const now = new Date().getTime();

      const distance: any = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (showType === "short") {
        if (days) setCounter(days + "d " + hours + "h");
        else if (hours)
          setCounter(hours + "h " + minutes + "m " + seconds + "s");
        else setCounter(minutes + "m " + seconds + "s");
      } else setCounter(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

      if (distance < 0) {
        clearInterval(interval);
        setCounter("EXPIRED");
      }
      if (!isCounting) clearInterval(interval);
    }, 1000);
    return () => {
      if (isCounting) clearInterval(interval);
    };
  }, []);

  return (
    <div className="CountdownComponent">
      <Icon>schedule</Icon> {counter}
    </div>
  );
};
export default React.memo(Countdown);
