import React, { useEffect } from "react";
import "./Traits.scss";
import dna2 from "../../images/dna2.png";
import traits from "./traits.jpeg";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const Traits = ({setActive}: any) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  useEffect(() => {
    if(inView){
      setActive('#philosophy')
    }
  }, [inView])

  const genes = {
    OCA2: [1, 22, 5041, 2233, 2029, 159, 143],
    HERC2: [22, 45, 5068, 2477, 1773, 195, 92],
    SLC24A5: [45, 70, 5143, 2185, 2110, 88, 79],
    MFSD12: [70, 100, 4979, 2243, 2067, 183, 133],
    EDAR: [100, 115, 5276, 2265, 1779, 157, 128],
    FGFR2: [115, 140, 4811, 2369, 2149, 145, 131],
    TCHH: [140, 175, 5215, 3064, 1143, 167, 16],
    MC1R: [175, 220, 5024, 2468, 1867, 130, 116],
    APOA1: [220, 240, 4720, 2260, 2169, 263, 193],
    FGFR3: [240, 270, 5116, 2652, 1600, 182, 55],
    ACTN3: [270, 290, 4823, 2740, 1707, 238, 97],
    ACE: [290, 315, 5496, 2315, 1612, 114, 68],
    DRD2: [315, 345, 5216, 2874, 1253, 236, 26],
    DRD4: [345, 370, 5026, 2861, 1451, 198, 69],
    KATNAL2: [370, 390, 5247, 2530, 1597, 163, 68],
    PCDH15: [390, 415, 4851, 2848, 1620, 190, 96],
    WSCD2: [390, 435, 4879, 2602, 1841, 155, 128],
    MAOA: [435, 450, 5101, 3122, 1146, 206, 30],
    pik3ca: [450, 455, 6766, 2560, 2505, 347, 334],
    MPG1: [455, 475, 5284, 2279, 1769, 152, 111],
    MPG2: [475, 501, 4372, 3126, 1536, 437, 134],
  };

  return (
    <div ref={ref} className="Traits" id="philosophy">
      <div className="container">
        <p className="title">Philosophy</p>
        <div className="brief">
          <div className="traits-table">
            <img src={dna2} alt="DNA"></img>
            <div className="content">
              <p>
                There are 4 different genetic codes: Adenine (A), Guanine (G), Thymine
                (T), Cytosine (C)
              </p>
              <p>
                In each DNA, there are 2 chromatids, a generation ID and parent DNAs
                datas. The DNAs, just like the evolution of the human kind, will be
                embarking in numerous adventures with our upcoming projects. Member count
                is increasing generation by generation. As an example, there are only 2
                DNAs in the first generation and ~150 DNAs in the fifth generation.
              </p>
              <p>
                These genetic codes will decide the next NFTs' behaviour and
                visualisation. Plus there are two different (X, Y) sex genes which you can
                see at the begining of each chromatids. XY gene refers male and XX gene
                refers female for the next NFTs.
              </p>
              <p>You can see each genes and base count on the genes table.</p>
              <Link to="/rarity-score" target="_blank" className="button rarity-calc-btn">
                Rarity Calculator
              </Link>
            </div>
            {/* <div className="table">
              <div className="row">
                <div className="cell"><b>Genes</b></div>
                <div className="cell"><b>Common</b></div>
                <div className="cell"><b>Uncommon</b></div>
                <div className="cell"><b>Rare</b></div>
                <div className="cell"><b>Super Rare</b></div>
                <div className="cell"><b>Mythic</b></div>
              </div>
              {
                Object.entries(genes).map(([gene, values]) =>
                  <div key={gene} className="row">
                    <div className="cell">{gene.toUpperCase()}</div>
                    <div className="cell">{values[2]}</div>
                    <div className="cell">{values[3]}</div>
                    <div className="cell">{values[4]}</div>
                    <div className="cell">{values[5]}</div>
                    <div className="cell">{values[6]}</div>
                  </div>
                )
              }
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Traits;
