import "./About.scss";
import { useEffect, useState } from "react";

import { keccak256, solidityKeccak256 } from "ethers/lib/utils";
import { CircularProgress } from "@material-ui/core";

import styled from "styled-components";

import toast, { Toaster } from "react-hot-toast";
import { ethers } from "ethers";
import axios from "axios";

import Modal from "@mui/material/Modal";

import { MerkleTree } from "merkletreejs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import contract from "../../contracts/nfdna_deployment_fuji.json"; // FUJI
import contract from "../../contracts/nfdna_deployment_mainnet.json";
import Confetti from "react-confetti";
import { mintStore, walletStore } from "../../redux/store";
import {
  setMintCountAction,
  setNewNftsAction,
  setWalletAction,
} from "../../redux/actions";
import { HashLink } from "react-router-hash-link";

const CounterText = styled.span``; // add your styles here

const mintType: string = "paidMint"; // "paidMint" | "whitelist"
const WL_ID = 3;
const FREE_MINT_ID = 1;
const DISABLE_MINT = true;

let whitelist: any = [];
let merkleTree: any = undefined;

const About = ({ claimAirdrop, modalClosed }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [balance, setBalance] = useState<number>();
  const [price, setPrice] = useState<number>(0);
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [wrongNet, setWrongNet] = useState(false);
  const [showConfetti, setshowConfetti] = useState(false);
  const [nftAnimation, setNftAnimation] = useState(false);
  const [mintCount, setMintCount] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [totalSupply, setTotalSupply] = useState(0);
  const [airdropCount, setAirdropCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  let [wallet, setWallet] = useState<any>();
  let [marketSolidity, setMarketSolidity] = useState<any>();
  let [merkleSolidity, setMerkleSolidity] = useState<any>();
  let [nfdnaSolidity, setNfdnaSolidity] = useState<any>();
  let [itemsRedeemed, setItemsRedeemed] = useState(0);
  let [provider, setProvider] = useState<any>();
  let [signer, setSigner] = useState<any>();
  let [nfts, setNfts] = useState<any>([]);
  let [newNFTs, setNewNFTs] = useState<any>(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  useEffect(() => {
    if (claimAirdrop) {
      airdropModal();
    }
  }, [claimAirdrop]);

  useEffect(() => {
    if (localStorage.getItem("connectedOnce") == "true" && !wallet) {
      connect();
    }
  }, []);

  const getWhitelist = async (whitelist_id: number) => {
    return axios
      .get(
        `https://nfdna-5a8df.hq.spicaengine.com/api/bucket/621e1917e65996002d1da660/data?filter={"whitelist_id":${whitelist_id}}`,
        {
          headers: {
            Authorization: "APIKEY 1cp31u19kzscgraz",
          },
        }
      )
      .then((res: any) => {
        if (res.data) {
          whitelist = res.data;
        }
      });
  };

  const createMerkleThree = () => {
    const merkleData: any = [];
    whitelist.forEach((el: any) => {
      merkleData.push(
        solidityKeccak256(["address", "uint256"], [el.address, String(el.count)])
      );
    });

    merkleTree = new MerkleTree(merkleData, (data: string) => keccak256(data).slice(2), {
      sort: true,
    });
  };

  const connect = async () => {
    localStorage.setItem("connectedOnce", "true");
    provider = new ethers.providers.Web3Provider((window as any).ethereum);
    await provider.send("eth_requestAccounts", []);
    signer = provider.getSigner();
    const userAddress = await signer.getAddress();
    const balance = await provider.getBalance(userAddress);
    wallet = userAddress;

    setSigner(signer);
    setProvider(provider);
    setWallet(wallet);
    walletStore.dispatch(setWalletAction(wallet));
    setBalance(parseInt(ethers.utils.formatEther(balance.toString())));

    getSolidity().catch((err) => {
      console.log("SOLIDITY", err);
      setWrongNet(true);
    });
    // refreshBalance(wallet).catch((err) => {
    //   console.log("BALANCE", err);
    //   setWrongNet(true);
    // });

    nfdnaSolidity.on("Transfer", async (from: any, to: any, tokenId: any) => {
      let signerAddress = await signer.getAddress();

      if (signerAddress == to) {
        let newMintCount = mintStore.getState().mintCount;
        mintStore.dispatch(setMintCountAction(newMintCount - 1));
        let temp = mintStore.getState().newNfts;
        temp.push(tokenId.toString());
        mintStore.dispatch(setNewNftsAction(temp));
        if (newMintCount - 1 == 0) {
          toast.dismiss();
          toast.success("Minting completed successfully", {
            position: "top-center",
          });
          setshowConfetti(true);
          setTimeout(() => {
            setNftAnimation(true);
          }, 1000);
          // refreshBalance(wallet)
          //   .then(() => {
          //     toast.dismiss();
          //     toast.success("Minting completed successfully", {
          //       position: "top-center",
          //     });
          //     setshowConfetti(true);
          //     setTimeout(() => {
          //       setNftAnimation(true);
          //     }, 1000);
          //   })
          //   .catch((err: any) => {
          //     console.log("REFRESH BALANCE ERROR: ", err);
          //   });
        }
      }
      setIsMinting(false);
    });
  };

  const getSolidity = async () => {
    marketSolidity = new ethers.Contract(
      contract.contracts.Market.address,
      contract.contracts.Market.abi,
      provider
    );
    nfdnaSolidity = new ethers.Contract(
      contract.contracts.NFDNA.address,
      contract.contracts.NFDNA.abi,
      provider
    );
    merkleSolidity = new ethers.Contract(
      contract.contracts.MerkleDrop.address,
      contract.contracts.MerkleDrop.abi,
      provider
    );

    setMarketSolidity(marketSolidity);
    setNfdnaSolidity(nfdnaSolidity);
    setMerkleSolidity(merkleSolidity);

    let current = await marketSolidity.current();
    let totalSupply = await nfdnaSolidity.totalSupply();

    setTotalSupply(totalSupply.toString());

    setPrice(Number(ethers.utils.formatEther(current.price)));
    setStartDate(new Date(current.date * 1000));
  };

  // const refreshBalance = async (userAddress: string) => {
  //   let userNFTs = [];
  //   for (let item of mintStore.getState().newNfts) {
  //     let tokenURI = await nfdnaSolidity.tokenURI(item.toString());
  //     const jsonData = await getNftJson(tokenURI);
  //     userNFTs.push(jsonData);
  //   }
  //   setNfts(userNFTs);
  //   nfts = userNFTs;
  //   mintStore.dispatch(setNewNftsAction([]));
  // };

  const getNftJson = async (url: string) => {
    return fetch(url)
      .then((response) => response.json())
      .then((jsonData) => {
        return jsonData;
      })
      .catch((error) => {
        console.error(error);
        return undefined;
      });
  };

  const handleMint = async () => {
    if (mintType == "whitelist") {
      await getWhitelist(WL_ID);
      createMerkleThree();
      const proofData = createProof();
      if (proofData) {
        merkleDrop(WL_ID, proofData.proof, proofData.availableCount, 0.9, mintCount);
      }
    } else if (mintType == "paidMint") {
      onMint();
    }
  };

  const calimAirdrop = async () => {
    const proofData = createProof();
    merkleDrop(
      FREE_MINT_ID,
      proofData?.proof,
      proofData?.availableCount,
      0,
      airdropCount
    );
    closeModal();
  };

  const airdropModal = async () => {
    await getWhitelist(FREE_MINT_ID);
    const wlData = whitelist.find(
      (el: any) => el.address.toLowerCase() == wallet.toLowerCase()
    );
    if (wlData) {
      let redeemedAmounts = await merkleSolidity.redeemedAmounts(FREE_MINT_ID, wallet);
      if (wlData.count == redeemedAmounts) {
        modalClosed();
        toast.error("You don't have any airdrop", {
          position: "top-center",
        });
      } else {
        createMerkleThree();
        const proofData = createProof();
        if (proofData) {
          setAirdropCount(proofData.availableCount);
          setModalOpen(true);
        } else {
          toast.error("Account in not eligible", {
            position: "top-center",
          });
        }
      }
    } else {
      modalClosed();
      toast.error("You don't have any airdrop", {
        position: "top-center",
      });
    }
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      const signerConnectedContract = await marketSolidity.connect(signer);
      const claimed = await signerConnectedContract.buy(mintCount, {
        value: ethers.utils.parseEther(
          ((price as number) * mintCount).toFixed(1).toString()
        ),
      });

      if (claimed) {
        toast.loading("Minting in progress...");
        // setNewNFTs(mintCount);
        mintStore.dispatch(setMintCountAction(mintCount));
        // newNFTs = mintCount;
      }
    } catch (error: any) {
      console.log(error);
      setIsMinting(false);
      let errMessage = "Minting cancelled";
      if (error?.data?.message.includes("err: insufficient funds for gas")) {
        errMessage = "Insufficient funds";
      } else if (
        error?.data?.message.includes("execution reverted: NFDNA: Sale has not began")
      ) {
        errMessage = "Public sale has not begun yet!";
      }
      toast.error(errMessage, {
        position: "top-center",
      });
    }
  };
  const createProof = () => {
    const wlData = whitelist.find(
      (el: any) => el.address.toLowerCase() == wallet.toLowerCase()
    );
    if (wlData) {
      const data = solidityKeccak256(
        ["address", "uint256"],
        [wallet, String(wlData.count)]
      );
      const proof = merkleTree.getHexProof(data);
      return { proof: proof, availableCount: wlData.count };
    } else {
      toast.error("You are not whitelisted", {
        position: "top-center",
      });
    }
  };

  const merkleDrop = async (
    listId: number,
    proof: any,
    availableCount: number,
    price: number,
    count: number
  ) => {
    try {
      setIsMinting(true);
      const signerConnectedContract = await merkleSolidity.connect(signer);
      const claim = await signerConnectedContract.claimFrom(
        listId,
        count,
        availableCount,
        proof,
        {
          value: ethers.utils.parseEther(String(price * count)),
        }
      );

      if (claim) {
        toast.loading("Minting in progress...");
        mintStore.dispatch(setMintCountAction(count));
      }
    } catch (error: any) {
      console.log(error);
      let errMessage = "An error occurred";
      if (error?.data?.message.includes("Whitelist not started")) {
        errMessage = "Pre-sale has not begun yet!";
      } else {
        errMessage = error.data?.message || error.message || "An error occurred";
      }
      setIsMinting(false);
      // toast.error(errMessage, {
      //   position: "top-center",
      // });

      toast.error(errMessage, {
        position: "top-center",
      });
    }
  };

  // const renderNewNfts = () => {
  //   return nfts.map((nft: any, index: number) => {
  //     return (
  //       <video
  //         autoPlay
  //         loop
  //         muted
  //         src={nft?.animation_url}
  //         key={`new-${index}`}
  //         className={`newNft ${nftAnimation && "newNftAnimation"}`}
  //       />
  //     );
  //   });
  // };

  const checkWallet = () => {
    setIsLoading(true);
    axios
      .get(
        `https://nfdna-5a8df.hq.spicaengine.com/api/bucket/621e1917e65996002d1da660/data?filter={"address":"${walletAddress}"}`,
        {
          headers: {
            Authorization: "APIKEY 1cp31u19kzscgraz",
          },
        }
      )
      .then((res: any) => {
        if (res.data.length) {
          toast.success("Your are whitelisted", {
            position: "top-center",
          });
        } else {
          toast.error("Your are not whitelisted", {
            position: "top-center",
          });
        }
        setWalletAddress("");
      })
      .catch((err) => {
        toast.dismiss();
        toast.error("Please try again later", {
          position: "top-center",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
    modalClosed();
  };

  const percentage = (partialValue: number, totalValue: number) => {
    return (100 * partialValue) / totalValue;
  };

  return (
    <div className="About">
      <Toaster />
      {showConfetti && (
        <div className="confettiContainer">
          <Confetti
            className="confetti"
            recycle={false}
            tweenDuration={4000}
            numberOfPieces={500}
            onConfettiComplete={() => {
              setNftAnimation(false);
              setTimeout(() => {
                setshowConfetti(false);
              }, 1000);
            }}
          />
          <div className="newNftContainer">
            <HashLink
              className={`seeNftText ${nftAnimation && "cardAnimation"}`}
              to="/your-nfts"
              target="_blank"
            >
              Click to see your NFT
            </HashLink>
          </div>
        </div>
      )}
      <div className="container">
        <div className="content">
          <p className="title">
            Welcome To <br />
            Non-Fungible DNAs
          </p>
          <p className="sub-title">
            A DNA that gives infinite whitelisting for <br /> our upcoming P2E games, NFT
            projects and
            <br /> partnered projects.
          </p>
          {/* <p>
            This project is designed as the first step of our NFT ecosystem. The
            uniqueness of these NFTs emerge from the very nature of human kind.
          </p> */}

          <div className="minting">
            <div className="top">
              {/* {!wallet ? (
                <>
                  <p>You need to connect your wallet before having an NFDNA piece.</p>
                  <div className="animated-button">
                    <div className="rainbow">
                      <span></span>
                      <span></span>
                    </div>
                    <button onClick={connect} className="button">
                      Connect Wallet
                    </button>
                  </div>
                </>
              ) : ( */}
              <div className={`wallet-info ` + !!wallet}>
                {wallet ? (
                  <>
                    <div className="progress-bar">
                      <div className="progress-span">
                        <span>Phase 1 minting closed</span>
                        {/* <span>Minted: {totalSupply}/9605</span> */}
                      </div>
                      {/* <div className="bar-container">
                    <div
                      className="bar"
                      style={{ width: `${percentage(totalSupply, 2000)}%` }}
                    ></div>
                    <div className="progress-span">
                      {mintType === "whitelist" ? (
                        <span>
                          Total Mint: {totalSupply}/2000
                        </span>
                      ) : (
                        <span>
                          Total Mint: {totalSupply}/9605
                        </span>
                      )}
                    </div>
                  </div> */}
                    </div>
                    {/* <p className="price">
                      <b>Public Sale: 1.2 AVAX</b> <br></br>
                     
                    </p> */}
                    <div className="wallet-info-bottom">
                      <div className={`buttons ${DISABLE_MINT && "disable"}`}>
                        <RemoveCircleIcon
                          className={`change-count-btn ${mintCount <= 1 && "disable"}`}
                          onClick={() => {
                            mintCount > 1 && !DISABLE_MINT && setMintCount(mintCount - 1);
                          }}
                        />
                        <div className="animated-button">
                          <div className="rainbow">
                            <span></span>
                            <span></span>
                          </div>
                          <button
                            className="button"
                            disabled={isMinting || DISABLE_MINT}
                            onClick={() => handleMint()}
                          >
                            {isMinting ? <CircularProgress /> : `Mint ${mintCount}`}
                          </button>
                        </div>
                        <AddCircleIcon
                          className={`change-count-btn ${
                            (mintCount >= 10 || DISABLE_MINT) && "disable"
                          }`}
                          onClick={() => {
                            mintCount < 10 &&
                              !DISABLE_MINT &&
                              setMintCount(mintCount + 1);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="animated-button connect-wallet">
                    <div className="rainbow">
                      <span></span>
                      <span></span>
                    </div>
                    <button onClick={connect} className="button">
                      Connect Wallet
                    </button>
                  </div>
                )}
              </div>
              {/* )} */}
            </div>
            {/* <div className="bottom">
              <div className="check-wallet">
                <input
                  placeholder="Wallet Address"
                  value={walletAddress}
                  onChange={(event) => setWalletAddress(event.target.value)}
                ></input>
                <button
                  className={`button ${walletAddress.length < 1 && "disabled"}`}
                  onClick={() => checkWallet()}
                  disabled={walletAddress.length < 1 || isLoading}
                >
                  {!isLoading ? (
                    <span>Check whitelist </span>
                  ) : (
                    <CircularProgress size={25} className="Spinner" />
                  )}
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <Modal onClose={closeModal} open={modalOpen} disableAutoFocus={true}>
        <div className="airdrop-modal">
          <div className="TabWiew">
            <div>
              <div className="Tab Active Border">
                <span>You have {airdropCount} airdrop</span>
              </div>
            </div>
            <p>By clicking claim button, you will claim all airdrops you have</p>
            <div className="buttons">
              <button className="button" onClick={calimAirdrop}>
                Claim
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {wrongNet && (
        <div className="wrongNet">
          Please select Avalanche Network and refresh the page.
        </div>
      )}
    </div>
  );
};
interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default About;
