import "./Home.scss";
import React, { useEffect, useState } from "react";
import FAQ from "./components/FAQ/FAQ";
import About from "./components/About/About";
import Roadmap from "./components/Roadmap/Roadmap";
import Traits from "./components/Traits/Traits";
import WillBeUsedFor from "./components/WillBeUsedFor/WillBeUsedFor";
import Tokenomics from "./components/Tokenomics/Tokenomics";
import NFTs from "./components/NFTs/NFTs";
import Utilities from "./components/Utilities/Utilities";
import Navbar from "./components/Navbar/Navbar";
import Team from "./components/Team/Team";
import LoadingGif from "./images/v2/nfdna-loading.gif";

const skyLoader = new Image();
const manLoader = new Image();
const concept1Loader = new Image();
const concept2Loader = new Image();


const skySrc =
  "https://storage.googleapis.com/download/storage/v1/b/hq-nfdna-5a8df/o/62628303b0e7df002d8ba093?alt=media";
const manSrc =
  "https://storage.googleapis.com/download/storage/v1/b/hq-nfdna-5a8df/o/62628303b0e7df002d8ba092?alt=media";
const concept1Src =
  "https://storage.googleapis.com/download/storage/v1/b/hq-nfdna-5a8df/o/62628303b0e7df002d8ba090?alt=media";
const concept2Src =
  "https://storage.googleapis.com/download/storage/v1/b/hq-nfdna-5a8df/o/62628303b0e7df002d8ba091?alt=media";


const Home = () => {
  const [showMain, setShowMain] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [activeRef, setActiveRef] = useState("");
  const [claimAirdrop, setClaimAirdrop] = useState(false);
  const links = [
    { disable: false, ref: "#roadmap", displayName: "Roadmap" },
    { disable: false, ref: "#utilities", displayName: "Multi Utilities" },
    {
      disable: false,
      ref: "#philosophy",
      displayName: "Philosophy",
    },
    { disable: false, ref: "#faq", displayName: "FAQ" },
    {
      disable: false,
      href: "https://storage.googleapis.com/hq-nfdna-5a8df/62237f34cee1a5002dc2b873",
      displayName: "Whitepaper",
    },
  ];
  const [loaded, setLoaded] = useState(true);
  const [imageStatus, setImageStatus] = useState({
    sky: false,
    man: false,
    concept1: false,
    concept2: false,
  });
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {
    document.addEventListener("mousemove", parallax);
    var skyElem = document.getElementById("sky");
    var conceptBgElem = document.getElementById("concept-bg");
    var manElem = document.getElementById("man");

    function parallax(e: any) {
      let _w = window.innerWidth / 2;
      let _mouseX = e.clientX;
      if (showMain && skyElem && conceptBgElem && manElem) {
        skyElem.style.backgroundPositionX = `${0 - (_mouseX - _w) * 0.04}px`;
        conceptBgElem.style.backgroundPositionX = `${0 - (_mouseX - _w) * 0.02}px`;
        manElem.style.left = `${600 - (_mouseX - _w) * 0.02}px`;
      }
    }
  });

  useEffect(() => {
    concept1Loader.onload = () => {
      setImageStatus((prev) => {
        return { ...prev, concept1: true };
      });
    };
    concept2Loader.onload = () => {
      setImageStatus((prev) => {
        return { ...prev, concept2: true };
      });
    };
    skyLoader.onload = (res) => {
      setImageStatus((prev) => {
        return { ...prev, sky: true };
      });
    };
    manLoader.onload = () => {
      setImageStatus((prev) => {
        return { ...prev, man: true };
      });
    };

    concept1Loader.src = concept1Src;
    concept2Loader.src = concept2Src;
    skyLoader.src = skySrc;
    manLoader.src = manSrc;
  }, []);


  useEffect(() => {
    if (
      imageStatus.concept1 &&
      imageStatus.concept2 &&
      imageStatus.man &&
      imageStatus.sky
    ) {
      setIsLoaded(true);
    }
  }, [imageStatus]);


  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        setLoaded(false);
        setTimeout(() => {
          setShowMain(true);
        }, 10)
      }, 3000);
    }
  }, [isLoaded]);

  return (
    <>
      {isLoaded && (
        <>
          <div
            id="concept-bg"
            className="main-bg"
            style={{ backgroundImage: `url(${concept1Src}), url(${concept2Src})` }}
          ></div>
          <div
            id="man"
            className="main-bg"
            style={{ backgroundImage: `url(${manSrc})` }}
          ></div>
          <div
            id="sky"
            className="main-bg"
            style={{ backgroundImage: `url(${skySrc})` }}
          ></div>
        </>
      )}
      {loaded ? (
        <>
          <div className="main-overlay" />
          <img
            src={LoadingGif}
            className={`loading-gif ${isLoaded && "loading-gif-animation"}`}
          />
        </>
      ) : (
        <main className={`${showMain && "showMain"}`}>
          <Navbar
            links={links}
            claimAirdrop={() => setClaimAirdrop(true)}
            showAppButton={true}
            activeRef={activeRef}
          ></Navbar>
          <div className="mainContent">
            <About
              claimAirdrop={claimAirdrop}
              modalClosed={() => setClaimAirdrop(false)}
            ></About>
            <Roadmap
              setActive={(value: string) => {
                setActiveRef(value);
              }}
            ></Roadmap>
            <Utilities
              setActive={(value: string) => {
                setActiveRef(value);
              }}
            ></Utilities>
            <WillBeUsedFor></WillBeUsedFor>
            <Traits
              setActive={(value: string) => {
                setActiveRef(value);
              }}
            ></Traits>
            <Team></Team>
            <FAQ
              setActive={(value: string) => {
                setActiveRef(value);
              }}
              isEnd={(value: boolean) => setIsEnd(value)}
            ></FAQ>
          </div>
          <div className={`arrow-down-icon ${isEnd && "arrow-up"}`}>
            <div className="line line1"></div>
            <div className="line line2"></div>
          </div>
        </main>
      )}
    </>
  );
};

export default Home;
