import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import ConnectWallet from "../components/ConnectWallet/ConnectWallet";
import "./layout.scss";

const Layout = () => {
  const [activeLink, setActiveLink] = useState<string>("staking");
  // let navigate = useNavigate();
  // const navigateNavbar = (link: string) => {
  //   setActiveLink(link);
  //   navigate(`/app/${link}`);
  // };
  const links = [
    { disable: false, path: "/app/staking", displayName: "Staking" },
    { disable: false, path: "/app/my_nfdnas", displayName: "My NFDNAs" },
    {
      disable: true,
      path: "/app/breeding_center",
      displayName: "Breeding Center",
    },
    { disable: true, path: "/app/reincarnation", displayName: "Reincarnation" },
  ];

  return (
    <div className="Layout">
      <Navbar links={links}></Navbar>
      {/* <div className="NavBar">
        <ul>
          <li onClick={() => navigate("/")}>
            <img className="logo" src="/NFDNA_logo-min.png"></img>
          </li>
          {links.map((item, i) => (
            <li
              key={i}
              className={`${activeLink == item.ref && "Active"} `}
              onClick={() => navigateNavbar(item.ref)}
            >
              <button disabled={item.disable}>{item.displayName}</button>
            </li>
          ))}
        </ul>
        <ConnectWallet></ConnectWallet>
      </div> */}
      <Outlet />
    </div>
  );
};
export default Layout;
