import { MINT_COUNT, NEW_NFTS } from "./types";

export function mintReducer(state:any = {mintCount:0, newNfts: []}, action: any) {
  if (action.type === MINT_COUNT) {
    state['mintCount'] = action.data;
    return state;
  } if(action.type === NEW_NFTS){
    state['newNfts'] = action.data;
    return state;
  }

  return state;
}