import { REFRESH_NFTS } from "./types";

export function nftsReducer(state = [], action: any) {
  if (action.type === REFRESH_NFTS) {
    state = action.data;
    const key = "dna";
    const arrayUnique = [...new Map(state.map((item) => [item[key], item])).values()];
    return arrayUnique;
  }

  return state;
}