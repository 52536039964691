import React from "react";
import "./NFTs.scss";
import { CircularProgress } from "@material-ui/core";
import NftCard from "../../pages/nfdnapp/components/NftCard/NftCard";

const NFTs = ({ nfts = [], isLoading = true }: any) => {
  return (
    <div className="NFTs" id="nfts">
      <div className="container">
        <p className="title">My NFDNAs</p>
        {isLoading ? (
          <div className="spinner-container">
            <CircularProgress style={{ alignSelf: "center" }} />
          </div>
        ) : (
          !nfts.length && <p className="placeholder">You don't have any NFT</p>
        )}

        {typeof nfts.length ? (
          <div className="nfts">
            {/* {nfts.map((nft: any, index: number) => 
              <div key={`NFT${index}`} className="nft">
                <span>Loading NFT</span>
                <img
                  src={`https://ipfs.io/ipfs/${nft.image?.split("ipfs://")[1]}`}
                  alt={nft.name}
                ></img>
              </div>
            )} */}

            {nfts.length > 0 &&
              nfts.map((nft: any, index: number) => (
                <NftCard key={index} nft={nft}></NftCard>
              ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NFTs;
