import React, { useEffect } from "react";
import "./WillBeUsedFor.scss";
import wislLogo from "../../images/wisl-logo.png";


const WillBeUsedFor = () => {
  return (
    <div className="WillBeUsedFor" id="willbeusedfor">
      <div className="container">
        <p className="title">NFDNA Will Be Used For</p>
        <div className="whaleIslands">
          <img src={wislLogo} className="wislLogo"></img>
          <div className="video">
            <a href="https://whaleislands.com/">
              <video
                controls
                autoPlay
                loop
                muted
                width="500"
                height="280"
                className="iframe"
                src="https://storage.googleapis.com/download/storage/v1/b/hq-nfdna-5a8df/o/620bdfbfb8e05a002d40e0e8?alt=media&timestamp=1644945346603"
              />
            </a>
          </div>
          <div className="utility">
            <p className="utilityTitle">
              <b>Your DNAs</b> will open the gates of <b>Whale Islands</b>:
            </p>
            <p>Participating in-game DAO events</p>
            <p>Whitelisting to initial ship NFT offerings</p>
            <p>The NFDNA-only airdrop pools </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WillBeUsedFor;
