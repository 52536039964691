import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import discord from "../Navbar/discord.svg";
import "./FAQ.scss";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useInView } from "react-intersection-observer";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(18,20,32,1)",
  color: "#ffffff",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(18,20,32, .125)",
  backgroundColor: "rgb(18,20,32)",
  color: "#ffffff",
}));

const FAQ = ({ isEnd, setActive }: any) => {
  const [expanded, setExpanded] = useState<number>();
  const { ref, inView } = useInView({ threshold: 0.1 });

  useEffect(() => {
    isEnd(inView);
    if(inView){
      setActive('#faq')
    }
  }, [inView]);

  const questions = [
    {
      question: "Is there a limit?",
      answer:
        "Of course, as with all good things, there will be limits. Only 9605 of these valuable and rare NFTs will exist.",
    },
    {
      question: "Is there any limit per mint?",
      answer: "You can mint 10 NFDNAs for every transaction. No limit per wallet",
    },
    {
      question: "Any secondary market?",
      answer:
        "Kalao, Yetiswap, NFTrade at the begining. Our own secondary market will be released soon",
    },
    {
      question: "What is the mint price?",
      answer: "Pre-sale price is 0.9 AVAX. Public-sale is 1.2 AVAX"
    },
  ];

  return (
    <div ref={ref} className="FAQ" id="faq">
      <div className="container">
        <p className="title">FAQ</p>
        {questions.map((question, index) => (
          <Accordion
            key={"faq" + index}
            className={`FAQ-item` && expanded === index ? "activated" : ""}
            expanded={expanded === index}
            onClick={() => {
              expanded === index ? setExpanded(-1) : setExpanded(index);
            }}
          >
            <AccordionSummary>
              <Typography className="question">{question.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="answer">{question.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
