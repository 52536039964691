import {
  REFRESH_NFTS,
  SET_WALLET,
  MINT_COUNT,
  NEW_NFTS,
  VESTED_NFTS,
} from "./types";

export const refresh = (data: any) => {
  return {
    type: REFRESH_NFTS,
    data: data,
  };
};

export const setWalletAction = (wallet: any) => {
  return {
    type: SET_WALLET,
    data: wallet,
  };
};

export const setMintCountAction = (count: number) => {
  return {
    type: MINT_COUNT,
    data: count,
  };
};

export const setNewNftsAction = (newNfts: any) => {
  return {
    type: NEW_NFTS,
    data: newNfts,
  };
};
export const setVestedNftsAction = (vestedNfts: any) => {
  return {
    type: VESTED_NFTS,
    data: vestedNfts,
  };
};
