import { useState, useEffect } from "react";
import { CircularProgress, Icon } from "@mui/material";
import "./ConnectWallet.scss";

import React from "react";
import { walletStore } from "../../../../redux/store";
import { setWalletAction } from "../../../../redux/actions";
import NfdnaFujiContractService from "../../../../services/contract/nfdna_fuji_contract";
import { shortenAddress } from "../../../../candy-machine";

const ConnectWallet = () => {
  const [wallet, setWallet] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (localStorage.getItem("connectedOnce") == "true" && !wallet) {
      connect();
    }
  }, []);

  const connect = async () => {
    localStorage.setItem("connectedOnce", "true");
    let successConnection = true;
    try {
      await NfdnaFujiContractService.sendConnection();
    } catch (error) {
      setLoading(false);
      walletStore.dispatch(setWalletAction(undefined));
      successConnection = false;
      return;
    }
    await NfdnaFujiContractService.setUpContract().catch(() => {
      successConnection = false;
      return;
    });
    if (!successConnection) return;
    setLoading(true);
    const userAddress = await NfdnaFujiContractService.getSigner().getAddress();
    if (userAddress) {
      setWallet(userAddress);
      walletStore.dispatch(setWalletAction(userAddress));
    }
    setLoading(false);
  };
  return (
    <div className="WalletContainer" onClick={() => !wallet && connect()}>
      {loading ? (
        <CircularProgress />
      ) : (
        <span >
          {wallet ? shortenAddress(wallet) : "Connect Wallet"}
        </span>
      )}
    </div>
  );
};
export default React.memo(ConnectWallet);
