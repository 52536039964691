import "./Navbar.scss";
import { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { walletStore } from "../../redux/store";
import logo from "../../images/v2/nfdna-logo.gif";
import discord from "./discord.svg";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link, useNavigate } from "react-router-dom";
import ConnectWallet from "../../pages/nfdnapp/components/ConnectWallet/ConnectWallet";
import AppIcon from "../../images/v2/logo_x32.png";

const Navbar = ({ claimAirdrop, links, showAppButton = false, activeRef }: any) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenSideMenu, setOpenSideMenu] = useState(false);
  const [activeLink, setActiveLink] = useState<string>("/app/staking");
  let navigate = useNavigate();

  const navigateNavbar = (link: string) => {
    setActiveLink(link);
    setOpenSideMenu(false);
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  let [wallet, setWallet] = useState<any>();

  useEffect(() => {
    const walletSubs = walletStore.subscribe(() => setWallet(walletStore.getState()));
    return () => {
      walletSubs();
    };
  }, []);

  return (
    <nav className="navbar is-primary">
      <div className="container">
        <div className="navbar-brand">
          <a
            role="button"
            className={`navbar-burger burger ${isOpen && "is-active"}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setOpen(!isOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${isOpen && "is-active"}`}>
          <div className="navbar-start">
            {!isTabletOrMobile && (
              <>
                <div className="logo" onClick={() => navigate("/")}>
                  <img src={logo}></img>
                </div>
                {links.map((item: any, i: number) => {
                  if (item.path) {
                    return (
                      <HashLink
                        key={i}
                        className={`navbar-item ${item.disable && "disabled-link"}`}
                        to={item.path}
                        smooth
                        onClick={() => navigateNavbar(item.path)}
                      >
                        <span>{item.displayName}</span>
                        <div
                          className={`nav-line ${
                            activeLink == item.path && "active-line"
                          }`}
                        ></div>
                      </HashLink>
                      // <div
                      //   className={`navbar-item ${activeLink == item.path && "Active"} `}
                      //   key={i}
                      //   onClick={() => navigateNavbar(item.path)}
                      // >
                      //   <button className="navbar-link-button" disabled={item.disable}>
                      //     {item.displayName}
                      //   </button>
                      // </div>
                    );
                  }
                  if (item.ref) {
                    return (
                      <HashLink key={i} className="navbar-item" to={item.ref} smooth>
                        <span>{item.displayName}</span>
                        {/* <div
                        className={`nav-line ${activeRef == item.ref && "active-line"}`}
                      ></div> */}
                      </HashLink>
                    );
                  }
                  if (item.href) {
                    return (
                      <a key={i} className="navbar-item" href={item.href} target="_blank">
                        {item.displayName}
                      </a>
                    );
                  }
                })}
                <div className="navbar-item social-icons">
                  <a href="https://twitter.com/nfdnaclub" target="_blank">
                    <div className="social button">
                      <TwitterIcon></TwitterIcon>
                    </div>
                  </a>
                  <a href="http://discord.gg/T8cN62DbAj" target="_blank">
                    <div className="social button">
                      <img src={discord} alt="Discord"></img>
                    </div>
                  </a>
                </div>
              </>
            )}
            {showAppButton && (
              <div className="navbar-item navbar-end app">
                <Link to="/app">
                  <img src={AppIcon} />
                  <span>App</span>
                </Link>
              </div>
            )}
            <div className="navbar-item navbar-end">
              <ConnectWallet></ConnectWallet>
            </div>
          </div>
          {/* <div className="sidebar">
            <b>Projects</b>
            <a href="https://whaleislands.com/" target="_blank" className="portfolio-project">
              <img src={wislLogo}></img>
            </a>
            <a href="javascript:void()" className="portfolio-project">
              <img src="https://storage.googleapis.com/download/storage/v1/b/hq-nfdna-5a8df/o/61dc5094280c06002c81dac0?alt=media"></img>
            </a>
          </div> */}
          {isOpenSideMenu ? (
            <CloseIcon className="menu-icon" onClick={() => setOpenSideMenu(false)} />
          ) : (
            <MenuIcon className="menu-icon" onClick={() => setOpenSideMenu(true)} />
          )}
        </div>
        <div className={`side-menu-container ${isOpenSideMenu && "is-active"}`}>
          <div className={`side-menu ${isOpenSideMenu && "is-active"}`}>
            {links.map((item: any, i: number) => {
              if (item.path) {
                return (
                  <HashLink
                    key={i}
                    className={`navbar-item ${item.disable && "disabled-link"}`}
                    to={item.path}
                    smooth
                    onClick={() => navigateNavbar(item.path)}
                  >
                    <span>{item.displayName}</span>
                    <div
                      className={`nav-line ${activeLink == item.path && "active-line"}`}
                    ></div>
                  </HashLink>
                );
              }
              if (item.ref) {
                return (
                  <HashLink
                    key={i}
                    className="navbar-item"
                    to={item.ref}
                    smooth
                    onClick={() => setOpenSideMenu(false)}
                  >
                    {item.displayName}
                  </HashLink>
                );
              }
              if (item.href) {
                return (
                  <a key={i} className="navbar-item" href={item.href} target="_blank">
                    {item.displayName}
                  </a>
                );
              }
            })}
            <div className="navbar-item social-icons">
              <a href="https://twitter.com/nfdnaclub" target="_blank">
                <div className="social button">
                  <TwitterIcon></TwitterIcon>
                </div>
              </a>
              <a href="http://discord.gg/T8cN62DbAj" target="_blank">
                <div className="social button">
                  <img src={discord} alt="Discord"></img>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
