import { useEffect, useState } from "react";
import NFTs from "../../components/NFTs/NFTs";
import "./YourNFTs.scss";
import Contract from "../../contracts/nfdna_deployment_mainnet.json"; // NFDNA FUJI
// import Contract from "../../contracts/nfdna_deployment_mainnet.json";
// import Contract from "../../contracts/deployment_mainnet.json"; // GRAFITI BOIS
// import Contract from "../../contracts/deployment_fuji.json"; //  GRAFITI BOIS TEST FUJI
import { ethers } from "ethers";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const YourNFTS = () => {
  let navigate = useNavigate();
  // let [gbSolidity, setGbSolidity] = useState<any>();
  let [provider, setProvider] = useState<any>();
  let [nfdnaSolidity, setNfdnaSolidity] = useState<any>();
  // let [itemsRedeemed, setItemsRedeemed] = useState(0);
  let [nfts, setNfts] = useState<any>([]);

  let [signer, setSigner] = useState<any>();

  const [isLoading, setIsLoading] = useState(true);
  const [wrongNet, setWrongNet] = useState(false);

  useEffect(() => {
    connect();
  }, []);

  // const getSolidity = async () => {
  //   gbSolidity = new ethers.Contract(
  //     Contract.GraffitiBois.address,
  //     Contract.GraffitiBois.abi,
  //     provider
  //   );

  //   setGbSolidity(gbSolidity);
  // };

  // const refreshBalance = async (userAddress: string) => {
  //   itemsRedeemed = (await gbSolidity.balanceOf(userAddress)).toString();

  //   setItemsRedeemed(itemsRedeemed);
  //   let userNFTs = [];
  //   if (itemsRedeemed > 0) {
  //     for (let x = 0; x < itemsRedeemed; x++) {
  //       let nft = (await gbSolidity.tokenOfOwnerByIndex(userAddress, x)).toString();
  //       let tokenURI = await gbSolidity.tokenURI(nft);
  //       userNFTs.push(tokenURI);
  //     }
  //     await getNftsJsonData(userNFTs);
  //   }
  // };

  // const getNftsJsonData = async (userNFTs: any) => {
  //   for (let nft of userNFTs) {
  //     let hash = nft.split("ipfs://")[1];
  //     await axios
  //       .get(`https://ipfs.io/ipfs/${hash}.json`)
  //       .then((res) => {
  //         nfts = [...nfts, res.data];
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  //   setNfts(nfts);
  //   setIsLoading(false);
  // };

  const connect = async () => {
    try {
      localStorage.setItem("connectedOnce", "true");
      provider = new ethers.providers.Web3Provider((window as any).ethereum);
      await provider.send("eth_requestAccounts", []);
      signer = provider.getSigner();
      const userAddress = await signer.getAddress();

      setSigner(signer);
      setProvider(provider);

      getSolidity().catch((err) => {
        setWrongNet(true);
        setIsLoading(false);
      });

      refreshBalance(userAddress).catch((err) => {
        console.log(err);
        setWrongNet(true);
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getSolidity = async () => {
    nfdnaSolidity = new ethers.Contract(
      Contract.contracts.NFDNA.address,
      Contract.contracts.NFDNA.abi,
      provider
    );

    setNfdnaSolidity(nfdnaSolidity);
  };

  const refreshBalance = async (userAddress: string) => {
    let itemsRedeemed = (await nfdnaSolidity.balanceOf(userAddress)).toString();
    if (itemsRedeemed > 0) {
      let promiseArray = [];
      for (let x = 0; x < itemsRedeemed; x++) {
        promiseArray.push(
          nfdnaSolidity
            .tokenOfOwnerByIndex(userAddress, x)
            .then((bigN: any) => {
              let nft = bigN.toString();
              return nfdnaSolidity.tokenURI(nft);
            })
        );
      }
      let allNFTs = await Promise.all(promiseArray);
      setNfts([...allNFTs]);
      nfts.push(...allNFTs);
      getNftJson([...allNFTs]);
    } else {
      setIsLoading(false);
    }
  };

  const getNftJson = async (userNFTs: any) => {
    let resolvedNFTs: any = [];
    setIsLoading(false);
    for (let url of userNFTs) {
      let dnaID = url.split("/");
      dnaID = dnaID[dnaID.length - 1];
      axios
        .post(
          "https://nfdna-5a8df.hq.spicaengine.com/api/fn-execute/calculateRarity",
          {
            dnaId: dnaID,
          }
        )
        .then((rarityScore) => {
          resolvedNFTs.push(rarityScore.data.resultData.dnaData);
          if (resolvedNFTs.length == nfts.length) {
            resolvedNFTs.sort(
              (a: any, b: any) => b.rarity_score - a.rarity_score
            );
            setNfts([...resolvedNFTs]);
          }
        })
        .catch((err) => console.log);
    }
  };

  // const getNftJson = async (url: string) => {
  //   // TODO remove line in other deployment
  //   url = url.split(".json")[0];

  //   return fetch(url)
  //     .then((response) => response.json())
  //     .then((jsonData) => {
  //       return jsonData;
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       return undefined;
  //     });
  // };

  return (
    <div className="YourNFTS">
      <NFTs nfts={nfts} isLoading={isLoading}></NFTs>
      {wrongNet && (
        <div className="wrongNet">
          Please select Avalanche Network and refresh the page.
        </div>
      )}
    </div>
  );
};

export default YourNFTS;
