import Countdown from "../Countdown/Countdown";
import "./NftCard.scss";

const NftCard = ({ nft, showType = "large" }: any) => {
  const goToRarity = () => {
    window.open(
      `http://${window.location.host}/rarity-score/${nft?.dna_id}`,
      "_blank"
    );
  };
  return (
    <div onClick={goToRarity} className={`${showType} NftCard`}>
      <div className="rarity">Rarity: {nft.rarity_score}</div>
      <div className="video-container">
        <video autoPlay loop muted src={nft?.animation_url} className="nft" />
      </div>
      <span className="NftId">#{nft?.dna_id}</span>
      {nft.countdown && (
        <Countdown
          date={new Date(nft.countdown + 1000 * 60 * 60 * 24)}
          showType="short"
        ></Countdown>
      )}
    </div>
  );
};
export default NftCard;
