import React from "react";
import StakeBox from "../../components/stakeBox/stakeBox";
import "./staking.scss";
import axios from "axios";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { stakeStore, walletStore } from "../../../../redux/store";
import NfdnaFujiContractService from "../../../../services/contract/nfdna_fuji_contract";
import raritiesJSON from "../../../../services/contract/rarities.json";
import fnExecuteService from "../../../../services/fnExecute";
import { setVestedNftsAction } from "../../../../redux/actions";

const Staking = () => {
  let [unStakedNfts, setUnStakedNfts] = useState<any>();
  let [stakedNfts, setstakedNfts] = useState<any>();
  const [wallet, setWallet] = useState<string>();
  let [totalCmtd, setTotalCmtd] = useState<any>({ claimable: 0, vested: 0 });
  let [totalRarity, setTotalRarity] = useState<any>({
    minted: 0,
    mine: 0,
  });
  const [exchange, setExchange] = useState<any>({
    avax2usd: 0,
    cmdt2usdt: 0.1,
  });
  let cmdtsInterval: any;
  let [inVestingStakes, setInVestingStakes] = useState<any>([]);

  useEffect(() => {
    if (wallet) {
      getNfts();
    }
  }, [wallet]);

  const getNfts = async () => {
    await NfdnaFujiContractService.setUpContract().catch((e) => console.log(e));
    let stakedNftIds: any = [];
    let unstakedNftIds: any = [];
    let unstakedRedeemed;
    try {
      unstakedRedeemed = (
        await NfdnaFujiContractService.getSolidities().NFDNASolidity.balanceOf(
          wallet
        )
      ).toString();

      stakedNftIds =
        await NfdnaFujiContractService.getSolidities().StakeSolidity.stakesOf(
          wallet
        );
      cmdtsInterval = setInterval(() => {
        if (
          (stakedNfts && stakedNfts.length > 0) ||
          (unStakedNfts && unStakedNfts.length > 0)
        )
          setTotalCmdts();
      }, 10000);
    } catch (error) {
      console.log("err :", error);
      toast.error("Wrong Network!", {
        position: "top-center",
      });
      unStakedNfts = [];
      stakedNfts = [];
      refreshNfts();
      return;
    }
    setIntialValues();

    inVestingStakes = stakedNftIds
      .filter(
        (item: any) =>
          item.stakeDate.toString() != 0 &&
          new Date().getTime() - Number(item.stakeDate * 1000) <
            1000 * 60 * 60 * 24
      )
      .map((item: any) => {
        return {
          tokenId: item.tokenId.toNumber(),
          stakeDate: item.stakeDate * 1000,
        };
      });
    stakeStore.dispatch(setVestedNftsAction(inVestingStakes));
    setInVestingStakes(JSON.parse(JSON.stringify(inVestingStakes)));

    stakedNftIds = stakedNftIds
      .filter((item: any) => item.stakeDate.toString() != 0)
      .map((item: any) => Number(item.tokenId.toString()));
    if (unstakedRedeemed > 0) {
      for (let x = 0; x < unstakedRedeemed; x++) {
        let nft = (
          await NfdnaFujiContractService.getSolidities().NFDNASolidity.tokenOfOwnerByIndex(
            wallet,
            x
          )
        ).toString();
        unstakedNftIds.push(nft);
      }
    }
    let tempNfts = [];
    for (const nftType of [
      { type: "staked", nfts: stakedNftIds },
      { type: "unstaked", nfts: unstakedNftIds },
    ]) {
      if (nftType.nfts.length > 0) {
        for (let x = 0; x < nftType.nfts.length; x++) {
          let tokenURI =
            await NfdnaFujiContractService.getSolidities().NFDNASolidity.tokenURI(
              nftType.nfts[x]
            );
          tempNfts.push(tokenURI);
        }
        await getNftsJsonData(tempNfts, nftType.type);
      } else setNftsByKey[nftType.type]();
      tempNfts = [];
    }
    refreshNfts();
  };

  useEffect(() => {
    setWallet(walletStore.getState());
    const subsWallet = walletStore.subscribe(() =>
      setWallet(walletStore.getState())
    );
    return () => {
      subsWallet();
    };
  }, []);

  const getNftsJsonData = async (userNFTs: any, nftType: string) => {
    const nftDatas: any = [];
    for (let nft of userNFTs) {
      let hash = nft.split(".json")[0];
      await axios
        .get(hash)
        .then((res) => {
          let rarityData = raritiesJSON.find(
            (item) => item.nftId.toString() == res.data.name.split("#")[1]
          );
          res.data.rarity_score = rarityData?.rarity;
          res.data.dna_id = rarityData?.nftId;
          nftDatas.push(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setNftsByKey[nftType](nftDatas);
  };
  const setNftsByKey: any = {
    staked: (data: any) => {
      if (!stakedNfts) stakedNfts = [];
      if (data) stakedNfts = stakedNfts.concat(data);
      setstakedNfts(stakedNfts);
      // refreshNfts();
      return;
    },
    unstaked: (data: any) => {
      if (!unStakedNfts) unStakedNfts = [];
      if (data) unStakedNfts = unStakedNfts.concat(data);
      // refreshNfts();
      setUnStakedNfts(unStakedNfts);
      return;
    },
  };
  const changeBox = async (data: any) => {
    //add to  staking

    const stakeSignerConnectedContract =
      NfdnaFujiContractService.getConnectedContract(
        NfdnaFujiContractService.getSolidities().StakeSolidity
      );

    const nfdnaSignerConnectedContract =
      NfdnaFujiContractService.getConnectedContract(
        NfdnaFujiContractService.getSolidities().NFDNASolidity
      );
    if (data.type === 0) {
      NfdnaFujiContractService.getSolidities().StakeSolidity.on(
        "Staked",
        async (staker: any, tokenId: any, rarity: any) => {
          if (
            wallet == staker.toString() &&
            data.nfts.some(
              (item: any) => item.name.split("#")[1] == tokenId.toString()
            )
          ) {
            unStakedNfts = unStakedNfts.filter(
              (item: any) =>
                !data.nfts.some((item2: any) => item2.name == item.name)
            );
            stakedNfts = stakedNfts.filter(
              (item: any) =>
                !data.nfts.some((item2: any) => item2.name == item.name)
            );
            stakedNfts.push(
              data.nfts.find(
                (item: any) => item.name.split("#")[1] == tokenId.toString()
              )
            );
            data.nfts = data.nfts.filter(
              (nft: any) => nft.name.split("#")[1] != tokenId.toString()
            );
            let storeInvestingStakes = stakeStore.getState().vestedNfts;
            storeInvestingStakes.push({
              tokenId: tokenId.toNumber(),
              stakeDate: new Date().getTime(),
            });
            stakeStore.dispatch(setVestedNftsAction(storeInvestingStakes));
            refreshNfts();
          }
          if (data.nfts.length == 0) {
            NfdnaFujiContractService.getSolidities().StakeSolidity.removeAllListeners(
              "Staked"
            );
          }
        }
      );
      const getApproved = await nfdnaSignerConnectedContract
        .isApprovedForAll(
          wallet,
          NfdnaFujiContractService.getContractJson().contracts.Stake.address
        )
        .catch((e: any) => console.log("e :", e));

      if (getApproved) await runBatchStake(data.nfts);
      else
        await nfdnaSignerConnectedContract
          .setApprovalForAll(
            NfdnaFujiContractService.getContractJson().contracts.Stake.address,
            true
          )
          .then(() => {
            NfdnaFujiContractService.getSolidities().NFDNASolidity.on(
              "ApprovalForAll",
              async (owner: any, operator: any, approved: any) => {
                if (owner == wallet) runBatchStake(data.nfts);
              }
            );
          })
          .catch((e: any) => {
            console.log("e setApprovalForAll :", e);
            toast.error("Staking cancelled", {
              position: "top-center",
            });
            refreshNfts();
          });
    }

    if (data.type === 1) {
      NfdnaFujiContractService.getSolidities().StakeSolidity.on(
        "Withdrawn",
        async (staker: any, tokenId: any) => {
          if (
            wallet == staker.toString() &&
            data.nfts.some(
              (item: any) => item.name.split("#")[1] == tokenId.toString()
            )
          ) {
            stakedNfts = stakedNfts.filter(
              (item: any) =>
                !data.nfts.some((item2: any) => item2.name == item.name)
            );
            unStakedNfts.push(
              data.nfts.find(
                (item: any) => item.name.split("#")[1] == tokenId.toString()
              )
            );
            data.nfts = data.nfts.filter(
              (nft: any) => nft.name.split("#")[1] != tokenId.toString()
            );
            let storeInvestingStakes = stakeStore.getState().vestedNfts;
            storeInvestingStakes = storeInvestingStakes.filter(
              (item: any) => item.tokenId.toString() != tokenId.toString()
            );
            stakeStore.dispatch(setVestedNftsAction(inVestingStakes));

            refreshNfts();
          }
          if (data.nfts.length == 0) {
            NfdnaFujiContractService.getSolidities().StakeSolidity.removeAllListeners(
              "Withdrawn"
            );
          }
        }
      );

      await stakeSignerConnectedContract
        .withdraw(data.nfts.map((item: any) => Number(item.name.split("#")[1])))
        .catch((e: any) => {
          console.log("e withdraw:", e);
          toast.error("Staking cancelled", {
            position: "top-center",
          });
          refreshNfts();
        });
    }
  };
  const runBatchStake = async (nfts: any) => {
    const stakeSignerConnectedContract =
      NfdnaFujiContractService.getConnectedContract(
        NfdnaFujiContractService.getSolidities().StakeSolidity
      );
    const stakeDatas = nfts.map((item: any) => {
      return {
        tokenId: Number(item.name.split("#")[1]),
        rarity: raritiesJSON.filter(
          (rarityData) => rarityData.nftId == Number(item.name.split("#")[1])
        )[0].rarity,
      };
    });

    await stakeSignerConnectedContract.stake(stakeDatas).catch((e: any) => {
      console.log("e batchStake:", e);
      toast.error("Staking cancelled", {
        position: "top-center",
      });
      refreshNfts();
    });
  };
  const getMyRarityScore = (calculateNfts: any) => {
    let myTotalRarity = 0;
    calculateNfts.forEach((element: any) => {
      let nftRarity = raritiesJSON.filter(
        (item) => item.nftId == Number(element.name.split("#")[1])
      )[0];
      myTotalRarity += nftRarity.rarity;
    });
    return myTotalRarity;
  };
  const refreshNfts = async () => {
    if (unStakedNfts)
      setUnStakedNfts(JSON.parse(JSON.stringify(unStakedNfts || [])));

    if (stakedNfts) {
      setstakedNfts(JSON.parse(JSON.stringify(stakedNfts || [])));
    }
    totalRarity.mine = 0;
    totalRarity.mine += getMyRarityScore(unStakedNfts);
    totalRarity.mine += getMyRarityScore(stakedNfts);
    setTotalRarity(JSON.parse(JSON.stringify(totalRarity)));
    setTotalCmdts();
  };
  const setIntialValues = async () => {
    const avax2usdData = await fnExecuteService.usdtEquivalent();
    setExchange({ ...exchange, avax2usd: avax2usdData });
    const totalMintedRarityScore =
      await fnExecuteService.getTotalMintedRarity();
    totalRarity.minted = totalMintedRarityScore;
    setTotalRarity(JSON.parse(JSON.stringify(totalRarity)));
  };
  const claimButtonClick = async () => {
    if (totalCmtd.claimable == 0) {
      toast.error("0 Claimable CMTD!", {
        position: "top-center",
      });
      return;
    }

    if (localStorage.getItem("claimOnce") != "true")
      await NfdnaFujiContractService.addCmtdtoMetamask();
    localStorage.setItem("claimOnce", "true");
    const stakeSignerConnectedContract =
      NfdnaFujiContractService.getConnectedContract(
        NfdnaFujiContractService.getSolidities().StakeSolidity
      );
    stakeSignerConnectedContract
      .claimReward(
        stakedNfts.map((item: any) => Number(item.name.split("#")[1]))
      )
      .then(() => {
        toast.loading("Claiming Start...");
        NfdnaFujiContractService.getSolidities().StakeSolidity.on(
          "ClaimedReward",
          async (staker: any, amount: any) => {
            if (wallet == staker.toString()) {
              toast.dismiss();
              toast.success("Claiming successfull", {
                position: "top-center",
              });
              setTotalCmdts();
              NfdnaFujiContractService.getSolidities().StakeSolidity.removeAllListeners(
                "ClaimedReward"
              );
            }
          }
        );
      })
      .catch((e: any) =>
        toast.error("Claiming canceled!", {
          position: "top-center",
        })
      );
  };
  const setTotalCmdts = async () => {
    const totalReward = await NfdnaFujiContractService.getSolidities()
      .StakeSolidity.rewardOf(
        stakedNfts.map((item: any) => Number(item.name.split("#")[1]))
      )
      .catch((e: any) => {
        console.log("e :", e);
        clearInterval(cmdtsInterval);
      });
    const vestedCmtd = await NfdnaFujiContractService.getSolidities()
      .StakeSolidity.rewardOfVested(
        stakeStore.getState().vestedNfts.map((item: any) => item.tokenId)
      )
      .catch((e: any) => {
        console.log("e :", e);
        clearInterval(cmdtsInterval);
      });

    if (vestedCmtd) {
      totalCmtd.vested = Number(
        Number(NfdnaFujiContractService.formatEther(vestedCmtd)).toFixed(5)
      );
    }
    if (totalReward) {
      totalCmtd.claimable = Number(
        Number(NfdnaFujiContractService.formatEther(totalReward)).toFixed(5)
      );
    }
    setTotalCmtd(JSON.parse(JSON.stringify(totalCmtd)));
  };

  return (
    <div className="Staking">
      <Toaster />
      <div className="ExchangeInfo">
        <div className="coin">
          <span>CMTD</span>
          <span>${exchange.cmdt2usdt}</span>
        </div>
        <div className="coin">
          <span>AVAX</span>
          <span>${exchange.avax2usd}</span>
        </div>
      </div>
      <div className="container">
        <div className="headerSection">
          <p className="title">Staking</p>

          <div className="StakingInfo">
            <div>
              <div className="Section">
                <span>Total Minted Rarity Score</span>
                <span>My Total Rarity Score</span>
              </div>
              <div className="Section">
                <span>{totalRarity.minted}</span>
                <span>{totalRarity.mine}</span>
              </div>
            </div>
            <div>
              <div className="Section">
                <span>NFDNA Staked</span>
                <span>Vested</span>
                <span>Claimable</span>
              </div>
              <div className="Section">
                <span>{stakedNfts?.length}</span>
                <span>{totalCmtd.vested} CMTD</span>
                <span>{totalCmtd.claimable} CMTD</span>
              </div>
            </div>
            <span className="ClaimButton" onClick={claimButtonClick}>
              <span>Claim</span>
            </span>
          </div>
        </div>

        <div className="Boxes">
          <StakeBox
            changeBox={changeBox}
            nfts={unStakedNfts}
            title={"Unstaked"}
            type={0}
          />
          <StakeBox
            changeBox={changeBox}
            nfts={stakedNfts}
            title={"Staked"}
            type={1}
            vesteds={stakeStore.getState().vestedNfts}
          />
        </div>
      </div>
    </div>
  );
};
export default React.memo(Staking);
