import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Incubation.scss";
import { HashLink } from "react-router-hash-link";

const Incubation = () => {
  const [projects, setProjects] = useState<any>([]);

  const getProjects = () => {
    axios
      .get(`https://nfdna-5a8df.hq.spicaengine.com/api/fn-execute/getUpcomingSeries`)
      .then((res: any) => {
        setProjects(res.data?.series || []);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="Incubation">
      <div className="container">
        <p className="title">Incubation</p>
        <div className="projects">
          {projects.map((project: any, index: number) => (
            <div
              key={"project" + index}
              style={index % 2 == 0 ? { marginRight: "10px" } : { marginLeft: "10px" }}
              className={`card`}
            >
              <HashLink to={`/incubation/${project._id}`} target="_blank">
                <img src={project.thumbnail}></img>
              </HashLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Incubation;
