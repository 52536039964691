import React, { useEffect, useState, ComponentProps } from "react";
import axios from "axios";
import "./RarityScore.scss";
import { CircularProgress } from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import SearchBtn from "@mui/icons-material/Search";
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';
import Modal from "@mui/material/Modal";

type Props = {
  data: ComponentProps<typeof PieChart>['data'];
};

function makeTooltipContent(entry: Props['data'][0]) {
  return `${entry.title}`;
}

const RarityScore = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showRarity, setShowRarity] = useState(false);
  const [nftData, setNftData] = useState<any>("");
  const [hovered, setHovered] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [dnaId, setDnaId] = useState("");
  const pieData = [
    { title: '0-200', value: 4228, color: '#fec89a' },
    { title: '200-300', value: 2410, color: '#005f73' },
    { title: '300-400', value: 1390, color: '#0a9396' },
    { title: '400-500', value: 698, color: '#94d2bd' },
    { title: '500-600', value: 348, color: '#e9d8a6' },
    { title: '600-1000', value: 393, color: '#ee9b00' },
    { title: '1000-2000', value: 113, color: '#ca6702' },
    { title: '2000-3000', value: 8, color: '#bb3e03' },
    { title: '3000-7500', value: 15, color: '#ae2012' },
  ]

  useEffect(() => {
    if (params.id) {
      setDnaId(params.id);
      calculateRarity(params.id);
    }
  }, []);

  const calculateRarity = (id = "") => {
    setIsLoading(true);
    toast.loading("Data is loading...");
    axios
      .post(`https://nfdna-5a8df.hq.spicaengine.com/api/fn-execute/calculateRarity`, {
        dnaId: id || dnaId,
      })
      .then((res: any) => {
        toast.dismiss();
        if (res.data) {
          setNftData(res.data.resultData);
          setShowRarity(true);
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(error.response.data.message, {
          position: "top-center",
        });
      })
      .finally(() => {
        setDnaId("");
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  return (
    <div className="RarityScore">
      <Toaster />
      <div className="container">
        <p className="title">Calculate NFT Rarity Score</p>
        <div className="searchBox">
          <input
            value={dnaId}
            placeholder="DNA ID"
            onChange={(event) => setDnaId(event.target.value)}
          ></input>
          <button
            onClick={() => calculateRarity()}
            disabled={dnaId.length < 1 || isLoading}
          >
            <SearchBtn />
          </button>
        </div>
        {/* <h1>Rarity score will be enable in 24 hours</h1> */}

        {showRarity && (
          <div className="rarityContent">
            <h1>Total Rarity Score {nftData.totalRarityScore} <div className="open-table" onClick={() => setModalOpen(true)}>?</div></h1>
            <Modal onClose={closeModal} open={modalOpen} disableAutoFocus={true}>
              <div className="modal">
                <div className="title">Rarity Range Chart</div>
                <div className="pie">
                  <div className="chartContainer" data-tip="" data-for="chart">
                    <PieChart
                      className="chart"
                      data={pieData}
                      onMouseOver={(_, index) => {
                        setHovered(index);
                      }}
                      onMouseOut={() => {
                        setHovered(null);
                      }}
                    />
                    <ReactTooltip
                      id="chart"
                      getContent={() =>
                        typeof hovered === 'number' ? makeTooltipContent(pieData[hovered]) : null
                      }
                    />
                  </div>
                  <div className="data">
                    <div className="range header">
                      <div className="title">Rarity range</div>
                      <div className="count">NFT count</div>
                    </div>
                    {
                      pieData.map((el: any, index: number) => (
                        <div className="range" key={`pie-row-${index}`}>
                          <div className="title">{el.title}</div>
                          <div className="count">{el.value}</div>
                        </div>))
                    }
                  </div>
                </div>
              </div>
            </Modal>
            <div className="topContainer">
              {/* <div className="image">
                <CircularProgress />
              </div> */}
              <video
                autoPlay
                loop
                muted
                className="video"
                width={300}
                src={nftData.dnaData?.animation_url}
              />
              <div className="metadata">
                <div className="metadataRow">
                  <span>DNA</span>
                  <span>{nftData.dnaData.dna_id}</span>
                </div>
                <div className="metadataRow">
                  <span>Generation</span>
                  <span>{nftData.dnaData.generation}</span>
                </div>

                <div className="metadataRow">
                  <span>Mother</span>
                  <span>After Sold Out</span>
                  {/* <span>{nftData.dnaData.mother}</span> */}
                </div>
                <div className="metadataRow">
                  <span>Father</span>
                  {/* <span>{nftData.dnaData.father}</span> */}
                  <span>After Sold Out</span>
                </div>

                <div className="metadataRow">
                  <span className="text-left">Chromosome - 1</span>
                  <span>{nftData.dnaData.chromosome_1}</span>
                </div>

                <div className="metadataRow">
                  <span className="text-left">Chromosome - 2</span>
                  <span>{nftData.dnaData.chromosome_2}</span>
                </div>
              </div>
            </div>
            <div className="table">
              <div className="row thead">
                <span>Genes</span>
                <span>Rarity</span>
                <span>Rarity Score</span>
              </div>
              {nftData?.rarityScore?.map((item: any, index: number) => (
                <div className="row content" key={`row-${index}`}>
                  <span className="geneName">{item.key}</span>
                  <span>{item.rarity}</span>
                  <span>{item.score}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RarityScore;
