import "./App.scss";
import { useEffect, useState } from "react";

import Home from "./Home";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TwitterInvite from "./pages/TwitterInvite/TwitterInvite";
import RarityScore from "./pages/RarityScore/RarityScore";
import YourNFTS from "./pages/YourNFTs/YourNFTs";
import Incubation from "./pages/Incubation/Incubation";
import IncubationProject from "./pages/IncubationProject/IncubationProject";
import Layout from "./pages/nfdnapp/layout/layout";
import Staking from "./pages/nfdnapp/pages/staking/staking";

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/participate" element={<TwitterInvite />}></Route>
          <Route path="/participate/:id" element={<TwitterInvite />}></Route>
          <Route path="/rarity-score" element={<RarityScore />}></Route>
          <Route path="/rarity-score/:id" element={<RarityScore />}></Route>
          <Route path="/incubation" element={<Incubation />}></Route>
          <Route path="/incubation/:id" element={<IncubationProject />}></Route>
          <Route path="/app" element={<Layout />}>
            <Route path="" element={<Staking />}></Route>
            <Route path="staking" element={<Staking />}></Route>
            <Route path="my_nfdnas" element={<YourNFTS />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
