import { createStore, applyMiddleware } from "redux";
import { nftsReducer } from "./nfts-reducer";
import { walletReducer } from "./wallet-reducer";
import thunk from "redux-thunk";
import { mintReducer } from "./mint-reducer";
import { stakeReducer } from "./stake-reducer";


const nftsStore = createStore(nftsReducer, applyMiddleware(thunk));
const walletStore = createStore(walletReducer, applyMiddleware(thunk));
const mintStore = createStore(mintReducer, applyMiddleware(thunk));
const stakeStore = createStore(stakeReducer, applyMiddleware(thunk));
export { nftsStore, walletStore, mintStore ,stakeStore};
