import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import "./Roadmap.scss";

const Roadmap = ({setActive}: any) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  useEffect(() => {
    if(inView){
      setActive('#roadmap')
    }
  }, [inView])

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const road = {
    past: [
      {
        label: "Cryptobiology",
        explanation:
          "Building the DNAs in real-life scenarios. All of humanity was created as a result of sexual reproduction and meiosis. Therefore, each DNA is similar to parents and childs.",
      },
      {
        label: "Humankind Generations",
        explanation:
          "Traits, DNAs, chromosomes... Everything saved to cloud database before TGE.",
      },
      {
        label: "Website Rollout",
        explanation: "Explanation of the most sophisticated NFT serie",
      },
    ],
    current: {
      label: "NFDNA Offering",
      explanation:
        "Gates are open now! Take your place to involve in the upcoming NFT based world.",
    },
    future: [
      {
        label: "Creator Funds",
        explanation:
          "All funds will be used for the other NFT creators to build their series. There are many individual talent who can be a part of the new metaverse.",
      },
      {
        label: "Whale Islands Investments",
        explanation:
          "In Q2 2022, Whale Islands will be launched on mainnet. All NFDNA holders will have ICO allocations and have a chance to join airdrop events from a special pool.",
      },
      // { label: "Viceroy Investments", explanation: "In Q3 2022, all NFDNA holders will have privileges from the second P2E game Viceroy. These privileges will be announced in Q2 2022." }
    ],
  };
  return (
    <div ref={ref} className={`Roadmap`} id="roadmap">
      <div className="container">
        <p className="title">Roadmap</p>
        {!isTabletOrMobile && (
          <div className="dot-container">
            {/* <div className="past">
              {road.past.map((point, index) => (
                <div key={"past" + index} className="dot">
                  <div className="label">{point.label}</div>
                </div>
              ))}
            </div> */}
            <div className="past">
              {road.past.map((point, index) => (
                <div key={"past" + index} className="dot">
                  <div className="label">{point.label}</div>
                </div>
              ))}
              <div className="dot active">
                <div className="label">{road.current.label}</div>
              </div>
              {road.future.map((point, index) => (
                <div key={"future" + index} className="dot future">
                  <div className="label">{point.label}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="roadmap-section">
          <div className="section">
            <div className="details">
              {road.past.map((point, index) => (
                <div key={"details" + index} className="row">
                  <p>{point.label}</p>
                  <p>{point.explanation}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="section right">
            <div className="details">
              {road.future.map((point, index) => (
                <div key={"future" + index} className="row">
                  <p>{point.label}</p>
                  <p>{point.explanation}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
