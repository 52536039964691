import axios from "axios";

export class fnExecuteService {
  private API_URL = "https://nfdna-5a8df.hq.spicaengine.com/api";
  private avaxToUsd: any;
  getTotalMintedRarity = () => {
    return axios
      .get(`${this.API_URL}/fn-execute/mintedRarityScore`)
      .then((res) => res.data);
  };
  usdtEquivalent = async () => {
    if (!this.avaxToUsd)
      this.avaxToUsd = await axios
        .get(
          "https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=usd"
        )
        .then((data: any) => data.data["avalanche-2"].usd);
    return this.avaxToUsd;
  };
}

export default new fnExecuteService();
