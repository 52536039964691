import axios from "axios";
// import { config } from "../bucket";
import { ethers } from "ethers";
import { keccak256, solidityKeccak256 } from "ethers/lib/utils";
import toast from "react-hot-toast";
import detectEthereumProvider from "@metamask/detect-provider";

import deploymentLocal from "./deployment_local.json";

export class NfdnaFujiContractService {
  private provider: any;
  private signer: any;
  private contract: any;
  private marketSolidity: any;
  private cmtdSolidity: any;
  private nfdnaSolidity: any;
  private stakeSolidity: any;
  private merkleDropSolidity: any;
  private API_URL: string = "https://nfdna-5a8df.hq.spicaengine.com/api";
  private CONFIG_BUCKET: string = "6220d397cee1a5002dc2afa8";
  private REACT_PUBLIC_API_KEY: string = "1cp31u19kzscgraz";

  setUpContract = async () => {
    if (!this.contract) await this.getContract();
    return this.setProviderData();
  };
  sendConnection = async () => {
    try {
      this.provider = new ethers.providers.Web3Provider(
        (window as any).ethereum
      );
      (window as any).ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xA86A",
            rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
            chainName: "Avalanche Mainnet C-Chain",
            nativeCurrency: {
              name: "AVAX",
              symbol: "AVAX",
              decimals: 18,
            },
            blockExplorerUrls: ["https://snowtrace.io/"],
          },
        ],
      });
      (window as any).ethereum.on("chainChanged", () => {
        window.location.reload();
      });
    } catch (error) {
      // toast.error("Missing Provider!");
      return;
    }
    return this.provider.send("eth_requestAccounts", []);
  };

  getContract = async () => {
    // this.contract = deploymentLocal;
    // return deploymentLocal;
    let conf: any = await axios(
      this.API_URL + "/bucket/" + this.CONFIG_BUCKET + "/data",
      {
        params: {
          // filter: { key: "nfdna_fuji_contract" },
          filter: { key: "nfdna_mainnet_contact" },
        },
        headers: {
          Authorization: `APIKEY ${this.REACT_PUBLIC_API_KEY}`,
        },
      }
    )
      .then((res) => res.data[0])
      .catch((e) => console.log("e :", e));
    if (!conf) {
      return {};
    }
    const deploymentFji = await axios.get(
      conf.file?.replace("/download", "") as any
    );
    this.contract = deploymentFji.data;
    return deploymentFji.data;
  };
  setProviderData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        this.provider = new ethers.providers.Web3Provider(
          (window as any).ethereum
        );
      } catch (error) {
        toast.error("Missing Provider!", {
          position: "top-center",
        });

        reject(false);
        return;
      }
      this.signer = this.provider.getSigner();
      await this.getSolidity().catch((e: any) => {
        toast.error("Wrong Network!", {
          position: "top-center",
        });
      });
      resolve(true);
    });
  };

  getSolidity = async () => {
    // this.marketSolidity = new ethers.Contract(
    //   this.contract.contracts.Market.address,
    //   this.contract.contracts.Market.abi,
    //   this.provider
    // );
    this.nfdnaSolidity = new ethers.Contract(
      this.contract.contracts.NFDNA.address,
      this.contract.contracts.NFDNA.abi,
      this.provider
    );
    this.stakeSolidity = new ethers.Contract(
      this.contract.contracts.Stake.address,
      this.contract.contracts.Stake.abi,
      this.signer
    );
    this.cmtdSolidity = new ethers.Contract(
      this.contract.contracts.CMTD.address,
      this.contract.contracts.CMTD.abi,
      this.signer
    );
    // this.merkleDropSolidity = new ethers.Contract(
    //   this.contract.contracts.MerkleDrop.address,
    //   this.contract.contracts.MerkleDrop.abi,
    //   this.provider
    // );
  };
  getSolidities = () => {
    return {
      // MarketSolidity: this.marketSolidity,
      NFDNASolidity: this.nfdnaSolidity,
      // MerkleDropSolidity: this.merkleDropSolidity,
      StakeSolidity: this.stakeSolidity,
      CMTDSolidity: this.cmtdSolidity,
    };
  };
  getConnectedContract = (connectedContract: any) => {
    return connectedContract.connect(this.signer);
  };
  getSigner = () => {
    return this.signer;
  };

  parseEther = (value: string) => {
    return ethers.utils.parseEther(value);
  };
  formatEther = (value: string) => {
    return ethers.utils.formatEther(value);
  };
  getContractJson = () => {
    return this.contract;
  };
  solidityKeccak = (types: any, values: any) => {
    return solidityKeccak256(types, values);
  };
  keccak = (data: any) => {
    return keccak256(data);
  };
  addToken = async (
    tokenAddress: any,
    tokenSymbol: any,
    tokenDecimals: any
  ) => {
    const providerEth: any = await detectEthereumProvider();
    new ethers.providers.Web3Provider((window as any).ethereum);
    await providerEth.sendAsync(
      {
        method: "metamask_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
          },
        },
        id: Math.round(Math.random() * 100000),
      },
      (err: any, added: any) => {
        if (err || "error" in added) {
          toast.error("There was a problem adding the token!", {
            position: "top-center",
          });
          return;
        }
      }
    );
  };
  addCmtdtoMetamask = async () => {
    await this.addToken(this.getSolidities().CMTDSolidity.address, "CMTD", 18);
  };
}
export default new NfdnaFujiContractService();
