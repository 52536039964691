import { VESTED_NFTS } from "./types";

export function stakeReducer(state: any = { vestedNfts: [] }, action: any) {
  if (action.type === VESTED_NFTS) {
    state["vestedNfts"] = action.data;
    return state;
  }

  return state;
}
