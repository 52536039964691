import "./Team.scss";

import dhLogo from "./dh-logo.png";
import tdLogo from "./td-logo.png";
import spicaLogo from "./spica-logo.png";
import unityLogo from "./unity-logo.png";
import avalancheLogo from "./avalanche.png";

import avasharks from "./nft-avasharks.svg";
import meerkat from "./nft-meerkat.png";
import slime from "./nft-slime.png";
import vending from "./nft-vending.png";
import hotels from "./nft-hotels.png";
import crafters from "./nft-crafters.png";


const Team = () => {
  return (
    <div className="Team" id="team">
      <div className="container">
        <p className="title">Consortium</p>
        <div className="images">
          <a href="https://dreamharvesters.com/" target="_blank"><img src={dhLogo}></img></a>
          <a href="https://teknodev.biz/" target="_blank"><img src={tdLogo}></img></a>
          <a href="https://spicaengine.com/" target="_blank"><img src={spicaLogo}></img></a>
          <a href="https://unity.com/" target="_blank"><img src={unityLogo}></img></a>
          <a href="https://www.avax.network/" target="_blank"><img src={avalancheLogo}></img></a>
        </div>
        {/* <p className="title">Partner Collections</p>
        <div className="collections">
          <a href="https://avasharks.io/" target="_blank"><img src={avasharks}></img></a>
          <a href="https://www.avaxmeerkats.com/" target="_blank"><img src={meerkat}></img></a>
          <a href="https://slimeshadies.com/" target="_blank"><img src={slime}></img></a>
          <a href="https://www.vendingmachinesnft.io/" target="_blank"><img src={vending}></img></a>
          <a href="https://www.avaxhotel.business/" target="_blank"><img src={hotels}></img></a>
          <a href="https://craftersonline.net/" target="_blank"><img src={crafters} className="crafters"></img></a>
        </div> */}
      </div>

    </div>
  );
};

export default Team;
