import React, { useEffect, useState } from "react";
import axios from "axios";
import "./IncubationProject.scss";
import { CircularProgress } from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Confetti from "react-confetti";
import { ethers } from "ethers";

import Contract from "../../contracts/deployment_mainnet.json";
// import Contract from "../../contracts/deployment_fuji.json"; // TEST FUJI

import { nftsStore, walletStore } from "../../redux/store";
import { refresh, setWalletAction } from "../../redux/actions";

const IncubationProject = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [project, setProject] = useState<any>();
  const [isNotEligible, setIsNotEligible] = useState(false);
  const [selectedDrop, setSelectedDrop] = useState(1);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  let [wallet, setWallet] = useState<any>();
  const [showConfetti, setshowConfetti] = useState(false);
  const [nftAnimation, setNftAnimation] = useState(false);
  const [price, setPrice] = useState<number>();

  let [provider, setProvider] = useState<any>();
  let [gbSolidity, setGbSolidity] = useState<any>();
  let [wlSolidity, setWlSolidity] = useState<any>();
  let [signer, setSigner] = useState<any>();
  const [wrongNet, setWrongNet] = useState(false);

  let [nfts, setNfts] = useState<any>([]);
  let [itemsRedeemed, setItemsRedeemed] = useState(0);

  const getProject = (id: string) => {
    axios
      .get(
        `https://nfdna-5a8df.hq.spicaengine.com/api/bucket/61dc1933280c06002c81d9d0/data/${id}`,
        {
          headers: {
            Authorization: "APIKEY 1cp31u19kzscgraz",
          },
        }
      )
      .then((res: any) => {
        console.log(res.data);
        if (res.data) {
          setProject(res.data);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (params.id) {
      getProject(params.id);
    }
    if (localStorage.getItem("connectedOnce") == "true" && !wallet) {
      connect();
    }
  }, []);

  const getSolidity = async () => {
    wlSolidity = new ethers.Contract(
      Contract.Whitelist.address,
      Contract.Whitelist.abi,
      provider
    );
    gbSolidity = new ethers.Contract(
      Contract.GraffitiBois.address,
      Contract.GraffitiBois.abi,
      provider
    );
    setWlSolidity(wlSolidity);
    setGbSolidity(gbSolidity);
    let price = await gbSolidity.price();
    setPrice(Number(ethers.utils.formatEther(price)));
  };

  const connect = async () => {
    localStorage.setItem("connectedOnce", "true");
    provider = new ethers.providers.Web3Provider((window as any).ethereum);
    await provider.send("eth_requestAccounts", []);
    signer = provider.getSigner();
    const userAddress = await signer.getAddress();
    wallet = userAddress;
    setSigner(signer);
    setProvider(provider);
    setWallet(wallet);
    walletStore.dispatch(setWalletAction(wallet));
    getSolidity().catch((err) => {
      setWrongNet(true);
    });
    refreshBalance(wallet).catch((err) => {
      setWrongNet(true);
    });
    gbSolidity.on("Transfer", ({ from, to, tokenId }: any) => {
      if (signer.address == to) {
        refreshBalance(wallet).finally(() => {
          toast.dismiss();
          toast.success("Redeem completed successfully", {
            position: "top-center",
          });
          setshowConfetti(true);
          setTimeout(() => {
            setNftAnimation(true);
          }, 1000);
        });
      }
      setIsRedeeming(false);
    });
  };

  const refreshBalance = async (userAddress: string) => {
    itemsRedeemed = (await gbSolidity.balanceOf(userAddress)).toString();
    setItemsRedeemed(itemsRedeemed);
    let userNFTs = [];
    if (itemsRedeemed > 0) {
      for (let x = 0; x < itemsRedeemed; x++) {
        let nft = (await gbSolidity.tokenOfOwnerByIndex(userAddress, x)).toString();
        let tokenURI = await gbSolidity.tokenURI(nft);
        userNFTs.push(tokenURI);
      }
      await getNftsJsonData(userNFTs);
    }
  };

  const getNftsJsonData = async (userNFTs: any) => {
    for (let nft of userNFTs) {
      let hash = nft.split("ipfs://")[1];
      await axios
        .get(`https://ipfs.io/ipfs/${hash}.json`)
        .then((res) => {
          nfts = [...nfts, res.data];
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setNfts(nfts);
    nftsStore.dispatch(refresh(nfts));
  };

  const Redeem = async () => {
    try {
      setIsRedeeming(true);
      const signerConnectedContract = await wlSolidity.connect(signer);
      const claimed = await signerConnectedContract.redeem(selectedDrop);
      if (claimed) {
        setIsClaimed(true);
        toast.loading("Redeem in progress...");
      }
    } catch (error: any) {
      console.log("Error", error);
      setIsRedeeming(false);
      setIsNotEligible(true);
    }
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      const signerConnectedContract = await gbSolidity.connect(signer);
      const claimed = await signerConnectedContract.claim({
        value: ethers.utils.parseEther((price as number).toString()),
      });
      if (claimed) {
        toast.loading("Minting in progress...");
      }
    } catch (error: any) {
      console.log("Error", error);
      setIsMinting(false);
      toast.error("Minting cancelled", {
        position: "top-center",
      });
    }
  };

  const formatDate = (date: any) => {
    let day = new Date(date).getDate();
    let month = new Date(date).getMonth() + 1;
    let year = new Date(date).getFullYear();
    return `${day}-${month}-${year}`;
  };

  const renderGallery = () => {
    return project?.gallery?.map((img: string, index: number) => (
      <img key={index} src={img} />
    ));
  };

  return (
    <div className="IncubationProject">
      {showConfetti && (
        <div className="confettiContainer">
          <Confetti
            className="confetti"
            recycle={false}
            tweenDuration={4000}
            numberOfPieces={500}
            onConfettiComplete={() => {
              setNftAnimation(false);
              setTimeout(() => {
                setshowConfetti(false);
              }, 1000);
            }}
          />
          <div className="newNftContainer">
            <div className={`newNft  newNftAnimation`}>
              <img
                src={`https://ipfs.io/ipfs/${
                  nfts[nfts.length - 1]?.image?.split("ipfs://")[1]
                }`}
                alt={nfts[nfts.length - 1]?.name}
              ></img>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="spinner-container">
          <CircularProgress />
        </div>
      ) : (
        <div className="container">
          <p className="title">{project?.name}</p>
          <div className="project">
            <div className="carousel-container">
              <Carousel showThumbs={false} infiniteLoop showStatus={false} autoPlay>
                {renderGallery()}
              </Carousel>
            </div>

            <div className="card-metadata">
              <div className="Top">
                <h2>
                  {project?.name} . {project?.creator}
                </h2>
                <p className="metadata-texx ">{project?.description}</p>
                <h4>{formatDate(project?.date)}</h4>
                <p>
                  <span>Utility:</span> {project?.utility}
                </p>
                <p>
                  <span>Price:</span> {project?.price} AVAX
                </p>
                <p>
                  <span>Minting Date:</span>{" "}
                  {project?.minting_date || "Will be announced on Twitter"}
                </p>
              </div>

              <div className="TabWiew">
                <div>
                  {project?.airdrops?.map((item: any, index: number) => (
                    <div
                      key={index}
                      className={`Tab ${selectedDrop === index + 1 ? "Active" : ""}  ${
                        (index % project?.airdrops.length) - 1 != 0 ? "Border" : ""
                      }`}
                      onClick={() => setSelectedDrop(index + 1)}
                    >
                      <span>Drop {index + 1}</span>
                    </div>
                  ))}
                </div>
                <p>By clicking claim button, you will claim all airdrops you have</p>
                {isNotEligible && (
                  <p className="error-text"> Your account is not eligible!</p>
                )}
                <div className="buttons">
                  {!wallet ? (
                    <button onClick={connect} className="button">
                      Connect Wallet
                    </button>
                  ) : isRedeeming ? (
                    <CircularProgress />
                  ) : isClaimed ? (
                    <p>Claimed</p>
                  ) : !isNotEligible ? (
                    <button className="button" onClick={Redeem}>
                      Redeem
                    </button>
                  ) : null}
                  {/* <button className="button" onClick={onMint}>
                    Mint
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {wrongNet && (
        <div className="wrongNet">
          Please select Avalanche Network and refresh the page.
        </div>
      )}
    </div>
  );
};

export default IncubationProject;
