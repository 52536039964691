import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./Utilities.scss";
import architecture from "../../images/v2/architecture.png";

import breeding from "../../images/v2/icons_architecture/breeding.png";
import game_universe from "../../images/v2/icons_architecture/game_universe.png";
import nfdna from "../../images/v2/icons_architecture/nfdna.png";
import reincarnation from "../../images/v2/icons_architecture/reincarnation.png";
import scmtd from "../../images/v2/icons_architecture/scmtd.png";
import sdna from "../../images/v2/icons_architecture/sdna.png";
import viceroy from "../../images/v2/icons_architecture/viceroy.png";
import whale_islands from "../../images/v2/icons_architecture/whale_islands.png";

const data = [
  {
    img: nfdna,
    title: "NFDNA",
    desc: "<p>Stake and earn <b>$CMTD</b>, use for breeding and mint a new <b>NFDNA</b></p>",
  },
  {
    img: breeding,
    title: "Breeding Center",
    desc: "<p>Requires 2 NFDNAs and $DNA to finalize the breeding. $DNAs will be burnt after breeding.</p>",
  },
  {
    img: scmtd,
    title: "$CMTD (chromatid)",
    desc: "<p>Chromatids guarantees allocation from NFT auctions, ICOs and Private Sales to holders related with $CMTD amount</p>",
  },
  {
    img: sdna,
    title: "$DNA",
    desc: "<p>The governance and lottery token</p>",
  },
  {
    img: reincarnation,
    title: "Reincarnation Lab",
    desc: "<p>Takes $CMTD and dead NFTs, creates $DNA</p>",
  },
  {
    img: game_universe,
    title: "Our Gaming Universe",
    desc: "<p>Includes all P2E games we develop, Whale Islands, Viceroy and goes on…</p>",
  },
  {
    img: whale_islands,
    title: "Whale Islands",
    desc: "<p>The first resource management game of our universe. Whale Islands players will trade the goods between <b>Viceroy</b> players.</p>",
  },
  {
    img: viceroy,
    title: "Viceroy",
    desc: "<p>The first city building game of our universe. Viceroy players will produce and consume goods for the <b>Whale Islands</b> players</p>",
  },
];

const Utilities = ({setActive}: any) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if(inView){
      setActive('')
    }
  }, [inView])

  return (
    <div ref={ref}  className="Utilities" id="utilities">
      <div className="container">
        <p className="title">Multi Utilities</p>
        <div className="brief">
          <div className="content left">
            {data.map((item, index) => (
              <div className={`architectureRow ${index > 3 && "hide"}`} key={item.title}>
                <img src={item.img} />
                <div>
                  <p className="itemTitle">
                    <b>{item.title}</b>
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                </div>
              </div>
            ))}
          </div>
          <div className="usage">
            <span className="architecture-item brending">Brending</span>
            <span className="architecture-item staking">Staking</span>
            <span className="architecture-item using">Using</span>
            <span className="architecture-item burning">Burning</span>
            <span className="architecture-item reincarnate">Reincarnate</span>
            <span className="architecture-item dead">Dead NFTs</span>
            <span className="architecture-item governs">Governs</span>
            <img src={architecture} />
          </div>
          <div className="content">
            {data.map((item, index) => (
              <div className={`architectureRow ${index < 4 && "hide"}`} key={item.title}>
                <img src={item.img} />
                <div>
                  <p className="itemTitle">
                    <b>{item.title}</b>
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: item.desc }}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Utilities;
