import { Checkbox, CircularProgress, Icon, Modal } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import nfdna_fuji_contract from "../../../../services/contract/nfdna_fuji_contract";
import NftCard from "../NftCard/NftCard";
import "./stakeBox.scss";
import raritiesJSON from "../../../../services/contract/rarities.json";
import fnExecuteService from "../../../../services/fnExecute";

const StakeBox = ({ type, title, nfts, changeBox, vesteds }: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const [openSelectAll, setOpenSelectAll] = useState<boolean>(false);
  let [selections, setSelections] = useState<any>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [totalUsdPrice, setTotalUsdPrice] = useState<any>();
  const [exchange, setExchange] = useState<any>({
    avax2usd: 0,
  });
  const [boxNfts, setBoxNfts] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    if (nfts) {
      setBoxNfts(JSON.parse(JSON.stringify(nfts)));
      setCountdowns(nfts);
      setLoading(false);
      // if (type == 1) setEarnings(nfts);
      // if (type == 0) clearErnings(nfts);
    }
  }, [nfts]);

  // const setExhanges = async () => {
  //   const avax2usdData = await fnExecuteService.usdtEquivalent();
  //   setExchange({ ...exchange, avax2usd: avax2usdData });
  // };

  const setCountdowns = (mapNfts: any) => {
    if (vesteds) {
      mapNfts = mapNfts.map((item: any) => {
        item.countdown = vesteds.filter(
          (item2: any) => item.name == "NFDNA #" + item2.tokenId.toString()
        )[0]?.stakeDate;
        return item;
      });
    } else {
      mapNfts = mapNfts.map((item: any) => {
        delete item?.countdown;
        return item;
      });
    }
    setBoxNfts(mapNfts);
  };

  const handleSwitchSelectClick = () => {
    if (openSelect) {
      selections = [];
      setSelections(selections);
    } else setOpenSelectAll(false);

    setOpenSelect(!openSelect);
  };
  const handleSwitchSelectAllClick = () => {
    if (openSelectAll) selections = [];
    else selections = nfts.map((item: any) => item.name);
    setSelections(selections);
    setOpenSelectAll(!openSelectAll);
  };

  const handleClickCheck = (event: any) => {
    if (event.target.checked) selections.push(event.target.value);
    else
      selections = selections.filter((item: any) => item != event.target.value);
    setSelections(JSON.parse(JSON.stringify(selections)));
  };
  const discardBox = () => {
    setOpenModal(false);
    changeBox({
      type,
      nfts: nfts.filter((item: any) => selections.includes(item.name)),
    });
    setLoading(true);
    setSelections([]);
    setOpenSelect(false);
    setOpenSelectAll(false);
  };

  return (
    <div className="StakeBox">
      <div className="Title">{title}</div>
      <div className="SelectionTab">
        {selections.length > 0 && (
          <div onClick={() => setOpenModal(true)} className="ColorWhite">
            <Icon>swap_horiz</Icon>
            {type == 0 ? "Stake" : "Unstake"}
          </div>
        )}
        {openSelect ? (
          <div onClick={handleSwitchSelectAllClick}>
            <Icon>
              {openSelectAll ? "check_box" : "check_box_outline_blank"}
            </Icon>
            Select All
          </div>
        ) : (
          ""
        )}

        <div onClick={handleSwitchSelectClick}>
          <Icon>{openSelect ? "close" : "rule"}</Icon>
          {openSelect ? "Cancel" : "Select"}
        </div>
      </div>
      {loading && (
        <div className="Progress">
          <CircularProgress sx={{ color: "var(--secondary)" }} />
        </div>
      )}
      <div className="Content">
        <div className="Nfts">
          {!loading &&
            boxNfts &&
            boxNfts.map((item: any, i: number) => (
              <div key={i} className={`Selections ${!openSelect && "Passive"}`}>
                <Checkbox
                  value={item.name}
                  onChange={handleClickCheck}
                  checked={selections.includes(item.name)}
                />
                <NftCard nft={item} showType={"medium"}></NftCard>
              </div>
            ))}
        </div>
      </div>
      <Modal
        disableAutoFocus={true}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="ModalContent">
          <div className="Title">
            Are you sure you want to {type == 0 ? "stake" : "unstake"}?
            {vesteds &&
            vesteds.filter((item: any) =>
              selections.includes("NFDNA #" + item.tokenId.toString())
            )[0] ? (
              <span>
                {` You have ${
                  vesteds.filter((item: any) =>
                    selections.includes("NFDNA #" + item.tokenId.toString())
                  ).length
                } vested nfts. If you continue with the transaction, you will lose the reward you won!`}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="Actions">
            <button onClick={() => setOpenModal(false)}>Cancel</button>
            <button onClick={discardBox}>Ok</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default React.memo(StakeBox);
